import React from "react"
import ClientesCadastrados from "../ClientesCadastrados"

export default function Clientes(){
    return(
        <>
            <div className="min-h-screen bg-white text-gray-900">
                <main className="max-w-[95%] mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                    <h1 className='font-bold text-2xl text-[#1e1e1e] pt-8 pb-3'>Clientes Cadastrados</h1>
                    <div className="mt-4 w-auto">
                        <ClientesCadastrados />
                    </div>
                </main>
            </div>
        </>
    )
}