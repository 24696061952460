import React, {useState} from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table'
// import { FiChevronsLeft, FiChevronLeft, FiChevronRight, FiChevronsRight  } from 'react-icons/fi'
import { classNames } from "./Utils";
// import FiltroGlobal from './FiltroGlobal'
// import Botao from './Botao'
import PageBotao from './PageBotao'
import Modal from '../Modal'
import FormVincularEmpresa from '../Form/FormVincularEmpresa';
import FormVincularEmpresaAdmin from '../Form/FormVincularEmpresaAdmin';
import { Link } from "react-router-dom"
import { AiOutlineEdit, AiFillEye } from 'react-icons/ai'
import { getTipoUsuario } from '../Form/helper'

export function StatusTag({ value }) {
    const status = (value ? ((value === 'A') ? 'Ativo' : (value === 'I') ? 'Inativo' : value.toLowerCase()) : "prospect");
    
    return (
      <span className={classNames("px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
          status.startsWith("ativo") ? "bg-green-100 text-green-700" : null,
          status.startsWith("inativo") ? "bg-red-100 text-red-700" : null
        )}>
        {status}
      </span>
    )
}

export function GrupoUsuario({ value }) {
  const grupo = getTipoUsuario(value);
  
  return (
    <span className="px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm">
      {grupo}
    </span>
  )
}

export function formatCnpj({value}){
    if(value){
      return value
        .replace(/\D+/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2') 
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2') 
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
    }
}

export function formatarDataPtBr({value}){
    if(value){
        let dateObj = new Date(value);

        let dateString = dateObj.toLocaleString('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            // hour: '2-digit',
            // minute:'2-digit',
            // second:'2-digit'
        });

        return dateString;
    }

    return value;
}

export function linkEdicaoEmpresa({value}){
    // py-1 px-4 border rounded
    return <Link className='text-gray-400 font-semibold py-1 px-4' target='_blank' title="Editar empresa" rel="noopener noreferrer" to={`/configuracoes/editar/empresa/${value}`}>
                <AiOutlineEdit className='w-[1.3em] h-[1.3em]'/>
            </Link>
}

export function linkVisualizarAgencia({value}){
  return <Link className='text-gray-400 font-semibold py-1 px-4' target='_blank' title="Visualizar agência" rel="noopener noreferrer" to={`/configuracoes/visualizar/agencia/${value}`}>
              <AiFillEye className='w-[1.3em] h-[1.3em]'/>
          </Link>
}

export function linkVisualizarCliente({value}){
  return <Link className='text-gray-400 font-semibold py-1 px-4' target='_blank' title="Visualizar cliente" rel="noopener noreferrer" to={`/configuracoes/visualizar/cliente/${value}`}>
              <AiFillEye className='w-[1.3em] h-[1.3em]'/>
          </Link>
}

export function Filtro ({ column: { filterValue, setFilter, preFilteredRows, id }}) {
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);
    return(
        <select
            name={id}
            id={id}
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">Todos</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

export default function Tabela({columns, data, showAddCompany, showAddCompanyAdmin, isLoading, limitSize = 10, hiddenColumnsTable = []}){
    const [showModal, setOpenModal] = useState(false);
    const openModal = () => {
        setOpenModal(true);
    }
    const closeModal = () => {
        setOpenModal(false);
    }
    const { 
        getTableProps, 
        getTableBodyProps, 
        headerGroups,  
        prepareRow,

        page,
        // canPreviousPage,
        // canNextPage,
        // pageOptions,
        // pageCount,
        // gotoPage,
        // nextPage,
        // previousPage,
        // setPageSize,

        state: { pageIndex, pageSize },
        // preGlobalFilteredRows,
        // setGlobalFilter,
     } =
    useTable({
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: limitSize,
        hiddenColumns: hiddenColumnsTable
      },
      manualPagination: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
    );

    return(
    <>
    {
          (isLoading)
            ?
            <div class="max-w-sm w-full mx-auto flex items-center justify-center min-h-[300px]">
              <div class="grid gap-3">
                <div class="flex items-center justify-center">
                  <svg class="animate-spin border-[#1346ed]" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                    <g id="Group 1000003709">
                      <circle id="Ellipse 717" cx="21" cy="21" r="17.9883" stroke="#D1D5DB" stroke-width="5" stroke-dasharray="4 4" />
                      <path id="Ellipse 715" d="M26.2226 38.2135C30.4948 36.9173 34.1323 34.0787 36.428 30.2497C38.7237 26.4206 39.5134 21.8746 38.6437 17.4957C37.7739 13.1167 35.3069 9.21759 31.7221 6.55647C28.1374 3.89535 23.6911 2.66236 19.2479 3.09723" stroke="#1346ed" stroke-width="5" />
                    </g>
                  </svg>
                </div>
                <span class="text-black text-sm font-normal leading-snug">Carregando informações..</span>
              </div>
            </div> 
        : 
          <>
            <div className='flex mb-2'>
              {/* <FiltroGlobal preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} /> */}
              {(showAddCompany || showAddCompanyAdmin) ? 
              <PageBotao
                className="rounded-r-md"
                onClick={openModal}
              >
                Adicionar empresa
              </PageBotao>
              : ''
              }
              {showModal && <Modal closeModal={closeModal} conteudo={((showAddCompanyAdmin) ? FormVincularEmpresaAdmin : FormVincularEmpresa)}/>}
              {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column) =>
                    column.Filter ? (
                      <div className='w-1/5 mt-2.5' key={column.id}>
                        <label className='' for={column.id}>{column.render("Header")}: </label>
                        {column.render("Filter")}
                      </div>
                    ) : null
                  )
              )}
              </div>
              <div className="mt-2 flex flex-col">
                <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table {...getTableProps()} border="1" className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-[#efefef]">
                            {headerGroups.map((headerGroup) => (
                              <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                  // column.getSortByToggleProps()
                                  <th {...column.getHeaderProps({
                                    style: { minWidth: column.minWidth, width: column.width },
                                  })} className="px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? column.isSortedDesc ? ' ▼' : ' ▲' : ''}
                                    </span>
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                            {page.map((row, i) => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps({style: {
                                      // minWidth: cell.column.minWidth,
                                      // width: cell.column.width,
                                      // maxWidth: cell.column.maxWidth,
                                    },})} className={`px-6 py-4 text-sm font-normal ${cell.column.className ?? ""}`}>{cell.render("Cell")}</td>;
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {/* <div className="py-3 flex items-center justify-between">
                            <div className="flex-1 flex justify-between sm:hidden">
                              <Botao onClick={() => previousPage()} disabled={!canPreviousPage}>Anterior</Botao>
                              <Botao onClick={() => nextPage()} disabled={!canNextPage}>Próximo</Botao>
                            </div>
                            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                <div className="flex gap-x-2">
                                    <span className="text-base text-gray-700"> Página <span className="font-medium">{state.pageIndex + 1}</span> de <span className="font-medium">{pageOptions.length}</span></span>
                                    <select className='bg-transparent'
                                    value={state.pageSize}
                                    onChange={e => {
                                        setPageSize(Number(e.target.value))
                                    }}
                                    >
                                    {[5, 10, 20].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                        Mostrar {pageSize}
                                        </option>
                                    ))}
                                    </select>
                                </div>
                                <div>
                                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                  <PageBotao
                                    className="rounded-l-md"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                  >
                                    <span className="sr-only">Primeira</span>
                                    <FiChevronsLeft className="h-5 w-5" aria-hidden="true" />
                                  </PageBotao>
                                  <PageBotao
                                    onClick={() => previousPage()}
                                    // disabled={!canPreviousPage}
                                  >
                                    <span className="sr-only">Anterior</span>
                                    <FiChevronLeft className="h-5 w-5" aria-hidden="true" />
                                  </PageBotao>
                                  <PageBotao
                                    onClick={() => nextPage()}
                                    // disabled={!canNextPage} 
                                    >
                                    <span className="sr-only">Próxima</span>
                                    <FiChevronRight className="h-5 w-5" aria-hidden="true" />
                                  </PageBotao>
                                  <PageBotao
                                    className="rounded-r-md"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                  >
                                    <span className="sr-only">Última</span>
                                    <FiChevronsRight className="h-5 w-5" aria-hidden="true" />
                                  </PageBotao>
                                </nav>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
            </div>
          </>
    }
    </>
    )
}