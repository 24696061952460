import React from "react"
import EmpresasCadastradas from "../EmpresasCadastradas"

export default function Empresas(){
    return(
        <>
            <div className="min-h-screen bg-white text-gray-900">
                <main className="max-w-[95%] mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                    <h1 className='font-bold text-2xl text-[#1e1e1e] pt-8 pb-3'>Empresas Cadastradas</h1>
                    <div className="mt-4 w-auto">
                        <EmpresasCadastradas />
                    </div>
                </main>
            </div>
        </>
    )
}