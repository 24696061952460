import React from 'react';
import { useTable, useFlexLayout, useGlobalFilter, useFilters, useSortBy, usePagination, useExpanded, useResizeColumns } from 'react-table'
import { classNames } from "./Utils";

export function StatusTag({ value }) {
  const status = value ? ((value === 'A') ? 'Ativo' : (value === 'I') ? 'Inativo' : value.toLowerCase()) : "prospect";

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("em aberto") ? "bg-yellow-100 text-yellow-600" : null,
        status.startsWith("pago") ? "bg-green-100 text-green-700" : null,
        status.startsWith("cancelado") ? "bg-red-100 text-red-700" : null,
        status.startsWith("pendente") ? "bg-yellow-100 text-yellow-600" : null,
      )}
    >
      {status}
    </span>
  );
}

export function TipoTag({ value }) {
  const status = value ? ((value === 'C') ? 'Cliente' : (value === 'F') ? 'Fornecedor' : value.toLowerCase()) : "prospect";

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("em aberto") ? "bg-yellow-100 text-yellow-600" : null,
        status.startsWith("pago") ? "bg-green-100 text-green-700" : null,
        status.startsWith("cancelado") ? "bg-red-100 text-red-700" : null,
        status.startsWith("pendente") ? "bg-yellow-100 text-yellow-600" : null,
      )}
    >
      {status}
    </span>
  );
}

export function Filtro ({ column: { filterValue, setFilter, preFilteredRows, id }}) {
  const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);
  return(
      <select
          name={id}
          id={id}
          value={filterValue}
          onChange={(e) => {
              setFilter(e.target.value || undefined);
          }}
      >
          <option value="">Todos</option>
          {options.map((option, i) => (
              <option key={i} value={option}>
                  {option}
              </option>
          ))}
      </select>
  )
}

export default function Tabela({
columns, 
data,
renderRowSubComponent,
rowOnClick,
rowClickHandler,
expandRows,
expandedRowObj,
selectEmpresas,
isLoading,
limitSize = 10
}){
  const {
      getTableProps, 
      getTableBodyProps, 
      headerGroups,  
      prepareRow,

      page,
      // canPreviousPage,
      // canNextPage,
      // pageOptions,
      // pageCount,
      // gotoPage,
      // nextPage,
      // previousPage,
      // setPageSize,

      state: { pageIndex, pageSize },
      // preGlobalFilteredRows,
      // setGlobalFilter,
   } =
  useTable({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: limitSize,
      expanded:
        expandRows && expandedRowObj.hasOwnProperty(0) ? expandedRowObj : {}
    },
    manualPagination: true,
  },
  useFilters,
  useGlobalFilter,
  useSortBy,
  useResizeColumns,
  useExpanded,
  usePagination,
  useFlexLayout
  );

  return(
  <>
    {(isLoading) 
        ? 
        <div class="max-w-sm w-full mx-auto flex items-center justify-center min-h-[300px]">
          <div class="grid gap-3">
            <div class="flex items-center justify-center">
              <svg class="animate-spin border-[#1346ed]" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                <g id="Group 1000003709">
                  <circle id="Ellipse 717" cx="21" cy="21" r="17.9883" stroke="#D1D5DB" stroke-width="5" stroke-dasharray="4 4" />
                  <path id="Ellipse 715" d="M26.2226 38.2135C30.4948 36.9173 34.1323 34.0787 36.428 30.2497C38.7237 26.4206 39.5134 21.8746 38.6437 17.4957C37.7739 13.1167 35.3069 9.21759 31.7221 6.55647C28.1374 3.89535 23.6911 2.66236 19.2479 3.09723" stroke="#1346ed" stroke-width="5" />
                </g>
              </svg>
            </div>
            <span class="text-black text-sm font-normal leading-snug">Carregando informações..</span>
          </div>
        </div>
        : 
        <>
          {/* <FiltroGlobal preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter}  showSelectEmpresas={(selectEmpresas) ? selectEmpresas : false}/> */}

          {/* {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((column) =>
                column.Filter ? (
                  <div key={column.id}>
                    <label for={column.id}>{column.render("Header")}: </label>
                    {column.render("Filter")}
                  </div>
                ) : null
              )
          )} */}

          <div className="mt-2 flex flex-col">
            <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table {...getTableProps()} border="1" className="divide-y divide-gray-200 w-full">
                          <thead className="bg-[#efefef]">
                            {headerGroups.map((headerGroup) => (
                              <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                  // <th {...column.getHeaderProps(column.getSortByToggleProps())} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  <th {...column.getHeaderProps()} className="px-6 py-3 text-left text-sm font-normal text-[#656565] tracking-wider">
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? column.isSortedDesc ? ' ▼' : ' ▲' : ''}
                                    </span>
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                            {page.map((row, i) => {
                              prepareRow(row);
                              return (
                                <React.Fragment key={i + "_frag"}>
                                        <tr
                                          {...row.getRowProps()}
                                          onClick={
                                            rowOnClick
                                              ? () => rowClickHandler(row.original)
                                              : () => ""
                                          }
                                        >
                                          {row.cells.map((cell) => {
                                            return (
                                              <td {...cell.getCellProps()} className={(cell.render('Header') === 'Total') ? "px-6 py-4 whitespace-nowrap text-sm" : "px-6 py-4 text-sm"}>
                                                <p class={(cell.render('Header') === 'Emissor') ? "line-clamp-2" : ''} title={(cell.render('Header') === 'Emissor') ? cell.value : ''}>{cell.render("Cell")}</p>
                                              </td>
                                            );
                                          })}
                                        </tr>
                                        {row.isExpanded ? (
                                          <tr>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm bg-[#f9f9f9]">
                                              <span className="subTable">
                                                {renderRowSubComponent({ row })}
                                              </span>
                                            </td>
                                          </tr>
                                        ) : null}
                                      </React.Fragment>
                                // <tr {...row.getRowProps()}>
                                //   {row.cells.map((cell) => {
                                //     return <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">{cell.render("Cell")}</td>;
                                //   })}
                                // </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {/* <div className="py-3 flex items-center justify-between">
                            <div className="flex-1 flex justify-between sm:hidden">
                              <Botao onClick={() => previousPage()} disabled={!canPreviousPage}>Anterior</Botao>
                              <Botao onClick={() => nextPage()} disabled={!canNextPage}>Próximo</Botao>
                            </div>
                            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                <div className="flex gap-x-2">
                                    <span className="text-base text-gray-700"> Página <span className="font-medium">{state.pageIndex + 1}</span> de <span className="font-medium">{pageOptions.length}</span></span>
                                    <select className='bg-transparent'
                                    value={state.pageSize}
                                    onChange={e => {
                                        setPageSize(Number(e.target.value))
                                    }}
                                    >
                                    {[5, 10, 20].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                        Mostrar {pageSize}
                                        </option>
                                    ))}
                                    </select>
                                </div>
                                <div>
                                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                  <PageBotao
                                    className="rounded-l-md"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                  >
                                    <span className="sr-only">Primeira</span>
                                    <FiChevronsLeft className="h-5 w-5" aria-hidden="true" />
                                  </PageBotao>
                                  <PageBotao
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                  >
                                    <span className="sr-only">Anterior</span>
                                    <FiChevronLeft className="h-5 w-5" aria-hidden="true" />
                                  </PageBotao>
                                  <PageBotao
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage
                                    }>
                                    <span className="sr-only">Próxima</span>
                                    <FiChevronRight className="h-5 w-5" aria-hidden="true" />
                                  </PageBotao>
                                  <PageBotao
                                    className="rounded-r-md"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                  >
                                    <span className="sr-only">Última</span>
                                    <FiChevronsRight className="h-5 w-5" aria-hidden="true" />
                                  </PageBotao>
                                </nav>
                              </div>
                            </div>
                          </div> */}
                    </div>
                </div>
            </div>
          </div>
        </>
    }
  </>
  )
}

Tabela.defaultProps = {
  rowOnClick: false,
  showPagination: false,
  expandRows: false,
  expandedRowObj: {}
};