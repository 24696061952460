import React, { useState, useEffect } from 'react';
import Tabela, { formatCnpj, formatarDataPtBr, linkEdicaoEmpresa} from './Tabela/Tabela'
import FilterData from './Tabela/FilterData'
import Pagination from './Tabela/Pagination'
import { selectCompaniesOrderBy } from './constants/filtro'

const getData = () => {
    const data = [
      {
        nomeempresa: "Potato LTDA",
        cnpj: "15.753.908/0001-43",
        datacadastro: "10/03/2019",
        status: "Ativo",
        tipo: "Cliente",
      },
      {
        nomeempresa: "123 LTDA",
        cnpj: "12.437.177/0001-39",
        datacadastro: "12/12/2019",
        status: "Ativo",
        tipo: "Fornecedor",
      },
      {
        nomeempresa: "AD Digital",
        cnpj: "15.753.908/0001-43",
        datacadastro: "02/03/2013",
        status: "Ativo",
        tipo: "Agencia",
      },
      {
        nomeempresa: "Batata Telecom",
        cnpj: "28.053.802/0001-89",
        datacadastro: "15/07/2011",
        status: "Inativo",
        tipo: "Cliente",
      },
      {
        nomeempresa: "Grupo Massa",
        cnpj: "35.287.923/0001-50",
        datacadastro: "26/08/2020",
        status: "Ativo",
        tipo: "Administrador",
      },
      {
        nomeempresa: "Rede Massa",
        cnpj: "35.287.923/0001-50",
        datacadastro: "01/01/2018",
        status: "Ativo",
        tipo: "Administrador",
      },
      {
        nomeempresa: "Massa FM",
        cnpj: "35.287.923/0001-50",
        datacadastro: "04/11/2016",
        status: "Ativo",
        tipo: "Afiliada Rádio",
      },
      {
        nomeempresa: "KINDALOO",
        cnpj: "56.163.595/0001-50",
        datacadastro: "2014-07-08",
        status: "Ativo",
        tipo: "Cliente"
      },
      {
        nomeempresa: "CHILLIUM",
        cnpj: "01.994.686/0001-46",
        datacadastro: "2019-09-02",
        status: "Inativo",
        tipo: "Cliente"
      },
      {
        nomeempresa: "GENMOM",
        cnpj: "66.088.384/0001-54",
        datacadastro: "2017-12-31",
        status: "Inativo",
        tipo: "Cliente"
      },
      {
        nomeempresa: "KONNECT",
        cnpj: "97.038.636/0001-64",
        datacadastro: "2021-07-22",
        status: "Ativo",
        tipo: "Cliente"
      },
      {
        nomeempresa: "PROTODYNE",
        cnpj: "72.148.616/0001-15",
        datacadastro: "2018-11-01",
        status: "Inativo",
        tipo: "Cliente"
      },
      {
        nomeempresa: "ZEPITOPE",
        cnpj: "55.349.766/0001-78",
        datacadastro: "2022-09-02",
        status: "Ativo",
        tipo: "Cliente"
      }
    ]
    return [...data]
  }

  export default function EmpresasCadastradas(){
      const [loading, setLoading] = useState(false);
      const [currentPage, setCurrentPage] = useState(1);
      const [limitPage, setLimitPage] = useState(10);
      const [totalItens, setTotalItens] = useState('');
      const [orderBy, setOrderBy] = useState('');
      const [sortType, setSortType] = useState('');
      const [search, setSearch] = useState('');
      // const [companies, setCompanies] = useState({});
      const [urlSearch, setUrlSearch] = useState('');
      const [companies, setCompanies] = useState(() => {
          const token = localStorage.getItem("token");
          fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/companies/'+limitPage+'?page='+currentPage,{
                  headers : {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                      'Authorization': "Bearer " + token
                  }
          })
          .then((response) => {
              return response.json();
          })
          .then((result) => {
              setCompanies(result.data.companies.data);
              setTotalItens(result.data.companies.total);
              return result.data;
          })
          .catch((err) => {
              // Erro
          })
          .finally(() => {
              setLoading(false);
          });
      });
    //   const [companies, setCompanies] = useState(() => {
    //       setLoading(true);
    //       setSearchParams(location.search);
    //       setCurrentPage(searchParams.get('page'));
    //       setLimitPage(searchParams.get('limit'));
    //       const token = localStorage.getItem("token");
    //       let pageSearch = ((searchParams.get('page') != 'undefined') ? searchParams.get('page') : 1);
    //       fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/companies/'+limitPage+'?page='+pageSearch,{
    //               headers : {
    //                   'Content-Type': 'application/json',
    //                   'Accept': 'application/json',
    //                   'Authorization': "Bearer " + token
    //               }
    //       })
    //       .then((response) => {
    //           return response.json();
    //       })
    //       .then((result) => {
    //           setCompanies(result.data.companies.data);
    //           setPageOptions(result.data.companies.links);
    //           setTotalItens(result.data.companies.total);
    //           setLastPage(result.data.companies.last_page);
    //           setNextPage(result.data.companies.next_page_url);
    //           return result.data;
    //       })
    //       .catch((err) => {
    //           // Erro
    //       })
    //       .finally(() => {
    //           setLoading(false);
    //       });
    // });

    useEffect(() => {
        setLoading(true);
        if(typeof(currentPage) != 'undefined'){
            const token = localStorage.getItem("token");
            fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/companies/'+limitPage+'?page='+currentPage+'&search='+search+'&order_by='+orderBy+'&sort_type='+sortType,{
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': "Bearer " + token
                    }
            })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                setCompanies(result.data.companies.data);
                setTotalItens(result.data.companies.total);
                return result.data;
            })
            .catch((err) => {
                // Erro
            })
            .finally(() => {
                setLoading(false);
            });
        }
        
    }, [currentPage]);

    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem("token");

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/companies/'+limitPage+'?page=1&search='+search+'&order_by='+orderBy+'&sort_type='+sortType,{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            setCompanies(result.data.companies.data);
            setTotalItens(result.data.companies.total);
            return result.data;
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        });
    }, [search, orderBy, sortType, limitPage]);

    const columns = React.useMemo(
        () => [
          {
            Header: "Nome da Empresa",
            accessor: "nome_fantasia",
          },
          {
            Header: "CNPJ",
            accessor: "cnpj",
            Cell: formatCnpj,
          },
          {
            Header: "Cadastrado em",
            accessor: "created_at",
            Cell: formatarDataPtBr,
          },
          {
            Header: "Status",
            accessor: "tipo",
          },
          // {
          //   Header: "Tipo",
          //   accessor: "tipo",
          //   Filter: Filtro,
          //   filter: 'includes',
          // },
          {
            Header: " ",
            accessor: "id",
            width: 60,
            Cell: linkEdicaoEmpresa
          },
        ], []);
      const data = React.useMemo(() => getData(), []);
    return(
    <div className="">
        <main className="mx-auto px-4 sm:px-6 lg:px-8 pt-4">
          <div className="mt-4">
            <div id="empresas-usuario">
              <FilterData 
                  totalItens={totalItens} 
                  searchChangeHandler={setSearch}
                  orderByChangeHandler={setOrderBy}
                  sortTypeChangeHandler={setSortType}
                  selectOrderBy={selectCompaniesOrderBy}
              />
                {((companies && Object.keys(companies).length > 0) || (search.length > 0)) ? (
                  <>
                      
                          <Tabela 
                              columns={columns} 
                              data={companies} 
                              isLoading={loading}
                              limitSize={limitPage}
                          />
                          <Pagination
                              totalRows={totalItens}
                              pageChangeHandler={setCurrentPage}
                              pageLimitChangeHandler={setLimitPage}
                              page={currentPage}
                              rowsPerPage={limitPage}
                          />
                  </>
                ): (loading) ? 
                  <div class="max-w-sm w-full mx-auto flex items-center justify-center min-h-[300px]">
                  <div class="grid gap-3">
                    <div class="flex items-center justify-center">
                      <svg class="animate-spin border-[#1346ed]" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                        <g id="Group 1000003709">
                          <circle id="Ellipse 717" cx="21" cy="21" r="17.9883" stroke="#D1D5DB" stroke-width="5" stroke-dasharray="4 4" />
                          <path id="Ellipse 715" d="M26.2226 38.2135C30.4948 36.9173 34.1323 34.0787 36.428 30.2497C38.7237 26.4206 39.5134 21.8746 38.6437 17.4957C37.7739 13.1167 35.3069 9.21759 31.7221 6.55647C28.1374 3.89535 23.6911 2.66236 19.2479 3.09723" stroke="#1346ed" stroke-width="5" />
                        </g>
                      </svg>
                    </div>
                    <span class="text-black text-sm font-normal leading-snug">Carregando informações..</span>
                  </div>
                </div> 
                  : 
                  (companies && Object.keys(companies).length === 0)
                  ?
                    <span>
                        Nenhuma informação foi encontrada.
                    </span>
                  :
                  'Nenhuma empresa cadastrada ainda.'
                }
            </div>
          </div>
        </main>
    </div>
    )
  }