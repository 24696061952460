import React, { useState, useEffect } from 'react'
import { FiChevronDown, FiChevronRight  } from 'react-icons/fi'
import { AiOutlineEdit } from 'react-icons/ai'
import TabelaRowExpand from '../BoletoRowExpand.jsx'
import Tabela, {Filtro, StatusTag, TipoTag} from '../ExpandTabela/ExpandTabela.jsx'
import { formatarDataPtBr, formatCnpj } from '../Tabela/Tabela'
import { data } from '../constants/gestaoUsuarioInfo.js'
import { Link } from "react-router-dom"
import FilterData from '../Tabela/FilterData'
import Pagination from '../Tabela/Pagination'
import { selectNivel, selectUsersOrderBy } from '../constants/filtro'

export default function GestaoUsuario(){
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPage, setLimitPage] = useState(10);
    const [totalItens, setTotalItens] = useState('');
    const [search, setSearch] = useState('');
    const [selectSearch, setSelectSearch] = useState('');
    // const [urlSearch, setUrlSearch] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [sortType, setSortType] = useState('');
    const [groupUser, setGroupUser] = useState('');
    const beginSearch = useState(false);
    // const [beginSearch, setBeginSearch] = useState(false);
    // const [users, setUsers] = useState({});

    const [users, setUsers] = useState(() => {
        setLoading(true);
        const token = localStorage.getItem("token");
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/users/'+limitPage+'?page=1',{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            setUsers(result.data.users.data);
            setTotalItens(result.data.users.total);
            return result.data;
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        });
    });

    useEffect(() => {
        if(typeof(currentPage) != 'undefined'){
            setLoading(true);
            const token = localStorage.getItem("token");
            fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/users/'+limitPage+'?page='+currentPage+'&search='+search+'&group='+groupUser+'&order_by='+orderBy+'&sort_type='+sortType,{
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': "Bearer " + token
                    }
            })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                setUsers(result.data.users.data);
                setTotalItens(result.data.users.total);
                return result.data;
            })
            .catch((err) => {
                // Erro
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem("token");

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/users/'+limitPage+'?page=1&search='+search+'&group='+groupUser+'&order_by='+orderBy+'&sort_type='+sortType,{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            setUsers(result.data.users.data);
            setTotalItens(result.data.users.total);
            return result.data;
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        });
    }, [search, orderBy, sortType, groupUser, limitPage]);

    const columns = React.useMemo(
        () => [
          {
            Header: () => null,
            id: "expander",
            width: 20,
            Cell: ({ row }) => (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                  <FiChevronDown />
                ) : (
                  <FiChevronRight />
                )}
              </span>
            )
          },
          {
            Header: "Nome",
            accessor: "name",
            width: 160
          },
          {
            Header: "Nº Empresas",
            accessor: "total_vinculos",
            width: 100
          },
          {
            Header: "Data da última ação",
            accessor: "data_ultima_acao",
            Cell: formatarDataPtBr,
            width: 100
          },
          {
            Header: "Registrado desde",
            accessor: "created_at",
            Cell: formatarDataPtBr,
            width: 120
          },
          {
            Header: "Status",
            accessor: "status",
            width: 80,
            Filter: Filtro,
            filter: 'includes',
            Cell: StatusTag,
          },
          // {
          //   Header: "Permissão",
          //   accessor: "permissao",
          //   Filter: Filtro,
          //   filter: 'includes',
          //   width: 100,
          // },
          {
            Header: " ",
            width: 60,
            accessor:'id',
            Cell: ({ value }) => (
              <Link className='text-gray-400 font-semibold' target='_blank' title="Editar usuário" rel="noopener noreferrer" to={`/configuracoes/editar/usuario/${value}`}>
                <AiOutlineEdit/>
              </Link>
            ),
          },
        ], []);
        
        const details = React.useMemo(
          () =>[
            {
              Header: "Empresa",
              accessor: "nome",
            },
            {
              Header: "CNPJ",
              accessor: "cnpj",
              Cell: formatCnpj,
            },
            {
              Header: "Data de Cadastro",
              accessor: "created_at",
              Cell: formatarDataPtBr,
            },
            {
              Header: "Tipo",
              accessor: "tipo_empresa",
              Cell: TipoTag,
            },
            {
                Header: "Situação",
                accessor: "situacao",
                Cell: StatusTag,
            },
        ]
        )
        const subTable = React.useCallback(
          ({ row }) =>
            row.original.total_vinculos > 0 ? (
              <TabelaRowExpand
                columns={details}
                data={row.original.empresas}
              />
            ) : (
              "Não há informações a serem exibidas."
            ),
          [details]
        );
      
        const expandedRows = React.useMemo(() => {
          if (data?.data) {
            let arr;
            let d = data.data;
            if (d.getGroupedUsuarioInfo.length > 0) {
              arr = d.getGroupedUsuarioInfo.map((sid, ind) => {
                return { [ind]: true };
              });
            }
            return arr;
          }
        }, []);

    return(
      <div className="min-h-screen bg-white text-gray-900">
        <main className="max-w-[95%] mx-auto px-4 sm:px-6 lg:px-8 pt-4">
          <h1 className='font-bold text-2xl text-[#1e1e1e] pt-8 pb-3'>Usuários Cadastrados</h1>
          <div className="mt-4 w-auto">
            {((users && Object.keys(users).length > 0) || (search.length > 0)) ? (
                <>
                    <div id="empresas-usuario">
                        <FilterData 
                            totalItens={totalItens} 
                            searchChangeHandler={setSearch}
                            orderByChangeHandler={setOrderBy}
                            sortTypeChangeHandler={setSortType}
                            selectStatusChangeHandler={setGroupUser}
                            selectChangeHandler={setSelectSearch}
                            itensSelect={selectNivel}
                            placeholderSelect="Selecione um grupo"
                            selectOrderBy={selectUsersOrderBy}
                        />
                        {(users && Object.keys(users).length > 0) ?
                            <Tabela columns={columns} data={users} showAddCompany="false" renderRowSubComponent={subTable} expandedRowObj={expandedRows} isLoading={loading} limitSize={limitPage}/>
                            : 'Nenhum dado a apresentar.'
                        }

                        <Pagination
                            totalRows={totalItens}
                            pageChangeHandler={setCurrentPage}
                            pageLimitChangeHandler={setLimitPage}
                            rowsPerPage={limitPage}
                            page={currentPage}
                            searchInput={beginSearch}
                        />
                    </div>
                </>
            ): (loading) ? 
                <div class="max-w-sm w-full mx-auto flex items-center justify-center min-h-[300px]">
                <div class="grid gap-3">
                  <div class="flex items-center justify-center">
                    <svg class="animate-spin border-[#1346ed]" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                      <g id="Group 1000003709">
                        <circle id="Ellipse 717" cx="21" cy="21" r="17.9883" stroke="#D1D5DB" stroke-width="5" stroke-dasharray="4 4" />
                        <path id="Ellipse 715" d="M26.2226 38.2135C30.4948 36.9173 34.1323 34.0787 36.428 30.2497C38.7237 26.4206 39.5134 21.8746 38.6437 17.4957C37.7739 13.1167 35.3069 9.21759 31.7221 6.55647C28.1374 3.89535 23.6911 2.66236 19.2479 3.09723" stroke="#1346ed" stroke-width="5" />
                      </g>
                    </svg>
                  </div>
                  <span class="text-black text-sm font-normal leading-snug">Carregando informações..</span>
                </div>
              </div> 
                : 
                (users && Object.keys(users).length > 0) ?
                  'Nenhum usuário cadastrado ainda.'
                  : 'Carregando...'
            }
            {/* {users && Object.keys(users).length > 0 ? (
              <Tabela 
                columns={columns} 
                data={users}
                renderRowSubComponent={subTable}
                expandedRowObj={expandedRows} />
              ) : (loading) ? 
              '' 
              : 
              <span>
                <em>Nenhuma informação encontrada.</em>
              </span>
            } */}
          </div>
        </main>
      </div>
    )
}