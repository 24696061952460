import React, { useState, useEffect } from 'react'
import ComprovantesVeiculacao from "../ComprovantesVeiculacao"
import Pagination from '../Tabela/Pagination'

export default function ComprovantesdeVeiculacao(runexecuted = false) {
  const [loading, setLoading] = useState(false);
  const [limitPage, setLimitPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItens, setTotalItens] = useState('');
  const [beginSearch, setBeginSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [campanhas, setCampanhas] = useState(null);
  
  useEffect(() => {
      // const fetchData = async () => { 
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const abortController = new AbortController();
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/campaigns/'+limitPage+'?page=1',{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setTotalItens(data.data.lists.total);
            setCampanhas(data.data.lists.data);
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        }); 
        return () => {
          abortController.abort()
        }
     } catch (error) {
       console.log(error);
     }
    // fetchData();
  }, []);  

  // useEffect(() => {
  //   setLoading(true);
  //   setBeginSearch(false);
  //   setCampanhas({});
  //   const token = localStorage.getItem("token");

  //   fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/campaigns/'+limitPage+'?page='+currentPage,{
  //           headers : {
  //               'Content-Type': 'application/json',
  //               'Accept': 'application/json',
  //               'Authorization': "Bearer " + token
  //           }
  //   })
  //   .then((response) => {
  //       return response.json();
  //   })
  //   .then((data) => {
  //       setTotalItens(data.data.lists.total);
  //       setCampanhas(data.data.lists.data);
  //       return data.data.lists.data;
  //   })
  //   .catch((err) => {
  //       // Erro
  //   })
  //   .finally(() => {
  //       setLoading(false);
  //   });
  // }, [currentPage]);

  // useEffect(() => {
  //   setLoading(true);
  //   setBeginSearch(true);
  //   setCampanhas({});
    
  //   const token = localStorage.getItem("token");
  //   fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/campaigns/'+limitPage+'?page='+currentPage+'&search='+search,{
  //       headers : {
  //           'Content-Type': 'application/json',
  //           'Accept': 'application/json',
  //           'Authorization': "Bearer " + token
  //       }
  //   })
  //   .then((response) => {
  //       return response.json();
  //   })
  //   .then((data) => {
  //       setTotalItens(data.data.lists.total);
  //       setCampanhas(data.data.lists.data);
  //       return data.data.lists.data;
  //   })
  //   .catch((err) => {
  //       // Erro
  //   })
  //   .finally(() => {
  //       setLoading(false);
  //   });
  // }, [search, limitPage]);
  return (
    <>      
      {campanhas && Object.keys(campanhas).length > 0 ? (
        <>
          <ComprovantesVeiculacao 
            data={campanhas} 
            limitSize={limitPage}
          />
          {/* <Pagination
              totalRows={totalItens}
              pageChangeHandler={setCurrentPage}
              pageLimitChangeHandler={setLimitPage}
              rowsPerPage={limitPage}
              page={currentPage}
              searchInput={beginSearch}
          /> */}
        </>
      ) : (loading) ?
      <div class="max-w-sm w-full mx-auto flex items-center justify-center min-h-[300px]">
        <div class="grid gap-3">
          <div class="flex items-center justify-center">
            <svg class="animate-spin border-[#1346ed]" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
              <g id="Group 1000003709">
                <circle id="Ellipse 717" cx="21" cy="21" r="17.9883" stroke="#D1D5DB" stroke-width="5" stroke-dasharray="4 4" />
                <path id="Ellipse 715" d="M26.2226 38.2135C30.4948 36.9173 34.1323 34.0787 36.428 30.2497C38.7237 26.4206 39.5134 21.8746 38.6437 17.4957C37.7739 13.1167 35.3069 9.21759 31.7221 6.55647C28.1374 3.89535 23.6911 2.66236 19.2479 3.09723" stroke="#1346ed" stroke-width="5" />
              </g>
            </svg>
          </div>
          <span class="text-black text-sm font-normal leading-snug">Carregando informações..</span>
        </div>
      </div>
      :
      (campanhas && Object.keys(campanhas).length === 0)
      ?
        <span>
            Nenhuma informação foi encontrada
        </span>
      :
        <span>
          Sem vínculo
        </span>
      }
    </>

  )
}
