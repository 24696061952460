import React, { useState, useEffect } from 'react';
import Tabela, { formatCnpj, formatarDataPtBr, linkVisualizarAgencia} from './Tabela/Tabela'
import FilterData from './Tabela/FilterData'
import Pagination from './Tabela/Pagination'
import { selectCompaniesOrderBy } from './constants/filtro'

export default function AgenciasCadastradas(){
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPage, setLimitPage] = useState(10);
    const [totalItens, setTotalItens] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [sortType, setSortType] = useState('');
    const [search, setSearch] = useState('');
    // const [agencies, setCompanies] = useState({});
    // const [urlSearch, setUrlSearch] = useState('');
    const [agencies, setCompanies] = useState(() => {
        const token = localStorage.getItem("token");
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/agencies/'+limitPage+'?page='+currentPage,{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            setCompanies(result.data.agencies.data);
            setTotalItens(result.data.agencies.total);
            return result.data;
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        });
    });

    useEffect(() => {
        setLoading(true);
        if(typeof(currentPage) != 'undefined'){
            const token = localStorage.getItem("token");
            fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/agencies/'+limitPage+'?page='+currentPage+'&search='+search+'&order_by='+orderBy+'&sort_type='+sortType,{
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': "Bearer " + token
                    }
            })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                setCompanies(result.data.agencies.data);
                setTotalItens(result.data.agencies.total);
                return result.data;
            })
            .catch((err) => {
                // Erro
            })
            .finally(() => {
                setLoading(false);
            });
        }
        
    }, [currentPage]);

    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem("token");

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/agencies/'+limitPage+'?page=1&search='+search+'&order_by='+orderBy+'&sort_type='+sortType,{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            setCompanies(result.data.agencies.data);
            setTotalItens(result.data.agencies.total);
            return result.data;
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        });
    }, [search, orderBy, sortType, limitPage]);

    const columns = React.useMemo(
        () => [
          {
            Header: "Nome da Empresa",
            accessor: "nome",
          },
          {
            Header: "CNPJ",
            accessor: "cnpj",
            Cell: formatCnpj,
          },
          {
            Header: "Cadastrado em",
            accessor: "created_at",
            Cell: formatarDataPtBr,
          },
          {
            Header: " ",
            accessor: "id",
            width: 60,
            Cell: linkVisualizarAgencia
          },
        ], []
    );
    
    return(
        <div className="">
            <main className="mx-auto px-4 sm:px-6 lg:px-8 pt-4">
            <div className="mt-4">
                <div id="empresas-usuario">
                <FilterData 
                    totalItens={totalItens} 
                    searchChangeHandler={setSearch}
                    orderByChangeHandler={setOrderBy}
                    sortTypeChangeHandler={setSortType}
                    selectOrderBy={selectCompaniesOrderBy}
                />
                    {((agencies && Object.keys(agencies).length > 0) || (search.length > 0)) ? (
                    <>
                        
                        <Tabela 
                            columns={columns} 
                            data={agencies} 
                            isLoading={loading}
                            limitSize={limitPage}
                        />
                        <Pagination
                            totalRows={totalItens}
                            pageChangeHandler={setCurrentPage}
                            pageLimitChangeHandler={setLimitPage}
                            page={currentPage}
                            rowsPerPage={limitPage}
                        />
                    </>
                    ): (loading) ? 
                    <div class="max-w-sm w-full mx-auto flex items-center justify-center min-h-[300px]">
                <div class="grid gap-3">
                  <div class="flex items-center justify-center">
                    <svg class="animate-spin border-[#1346ed]" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                      <g id="Group 1000003709">
                        <circle id="Ellipse 717" cx="21" cy="21" r="17.9883" stroke="#D1D5DB" stroke-width="5" stroke-dasharray="4 4" />
                        <path id="Ellipse 715" d="M26.2226 38.2135C30.4948 36.9173 34.1323 34.0787 36.428 30.2497C38.7237 26.4206 39.5134 21.8746 38.6437 17.4957C37.7739 13.1167 35.3069 9.21759 31.7221 6.55647C28.1374 3.89535 23.6911 2.66236 19.2479 3.09723" stroke="#1346ed" stroke-width="5" />
                      </g>
                    </svg>
                  </div>
                  <span class="text-black text-sm font-normal leading-snug">Carregando informações..</span>
                </div>
              </div> 
                    : 
                    (agencies && Object.keys(agencies).length === 0)
                    ?
                        <span>
                            Nenhuma informação foi encontrada.
                        </span>
                    :
                    'Nenhuma empresa cadastrada ainda.'
                    }
                </div>
            </div>
            </main>
        </div>
    )
}