import React from 'react';
import { useTable, useSortBy, usePagination, useExpanded, useResizeColumns } from 'react-table'

export default function Tabela({
  columns, 
  data,
  expandRows,
  expandedRowObj
}){
    const {
        getTableProps, 
        getTableBodyProps, 
        headerGroups,  
        prepareRow,
        page,
     } =
    useTable({
      columns,
      data,
      initialState: {
        expanded:
          expandRows && expandedRowObj.hasOwnProperty(0) ? expandedRowObj : {}
      },
    },
    useSortBy,
    useResizeColumns,
    useExpanded,
    usePagination
    );

    return(
    <>

    {headerGroups.map((headerGroup) =>
        headerGroup.headers.map((column) =>
          column.Filter ? (
            <div key={column.id}>
              <label for={column.id}>{column.render("Header")}: </label>
              {column.render("Filter")}
            </div>
          ) : null
        )
    )}

<div className="flex flex-col">
    <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 ">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg m-6">

    <table {...getTableProps()} border="1" className="min-w-full divide-y divide-white">
      <thead className="bg-[#efefef]">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="px-6 py-3 text-left text-sm font-normal text-[#656565] tracking-wider">
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap text-sm">{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
      </div>
      </div></div></div>
    </>
    )
}
